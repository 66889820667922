//var ruta = window.location.href;
var rutaAceptarTodasCookies = '/es/aceptar_todas_cookies';
var rutaAceptarCookiesFuncionales = '/es/aceptar_cookies_funcionales';

$("#usar_todas_las_cookies").click(function () {
    $.ajax({
        url: rutaAceptarTodasCookies,
        dataType: 'json',
        success: function (data) {
            $('#cookies_modal').hide();
        }
    });
});

$("#usar_cookies_funcionales").click(function () {
    $.ajax({
        url: rutaAceptarCookiesFuncionales,
        dataType: 'json',
        success: function (data) {
            $('#cookies_modal').hide();
        }
    });
});

$("#cambiar_cookies").click(function () {
    $('#cookies_modal').hide();
});

$('#consentimiento_cookies_tecnicas_0').prop("disabled",true);
$('#consentimiento_cookies_tecnicas_0').prop("checked",true);
$('#consentimiento_cookies_tecnicas_1').prop("disabled",true);